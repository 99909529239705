/* eslint-disable */
import React from 'react'
import firebase from '../firebase'
import { navigate } from 'gatsby'
import axios from 'axios'

const Popup = props => {
  const [count, setCount] = React.useState(false)
  const [firebaseId, setFireBaseID] = React.useState('')

  React.useEffect(() => {
    FirebaseId()
  }, [firebaseId])

  const getURLParameter = name => {
    return (
      decodeURIComponent(
        (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
          props.location.search
        ) || [null, ''])[1].replace(/\+/g, '%20')
      ) || null
    )
  }

  const FirebaseId = () => {
    if (firebaseId.length === 0) {
      setFireBaseID(firebase.auth.app.options_.authDomain.split('.')[0])
    }
  }
  const linkedInAuth = () => {
    if (firebaseId.length > 0) {
      let code = getURLParameter('code')
      let state = getURLParameter('state')
      let error = getURLParameter('error')

      if (error) {
        navigate('/login')
      } else if (!code) {
        // Start the auth flow.

        window.location.href =
          'https://us-central1-' + firebaseId + '.cloudfunctions.net/redirect'
      } else if (!count) {
        // Use JSONP to load the 'token' Firebase Function to exchange the auth code against a Firebase custom token.
        const script = window.document.createElement('script')
        script.type = 'text/javascript'
        // This is the URL to the HTTP triggered 'token' Firebase Function.
        // See https://firebase.google.com/docs/functions.
        var tokenFunctionURL =
          'https://us-central1-' + firebaseId + '.cloudfunctions.net/token'
        script.src =
          tokenFunctionURL +
          '?code=' +
          encodeURIComponent(code) +
          '&state=' +
          encodeURIComponent(state)

        const tokenFunc = decodeURIComponent(script.src)

        axios
          .get(tokenFunc, {
            crossDomain: true,
          })
          .then(res => {
            if (res) {
              const token = res.data.token

              firebase.customLogin(token).then(socialAuthUser => {
                let neW = socialAuthUser.additionalUserInfo.isNewUser
                setCount(true)
                if (neW) {
                  //  firebase.db.collection('users').doc(socialAuthUser.user.uid).set({
                  //   username: socialAuthUser.additionalUserInfo.profile.name,
                  //   // email: socialAuthUser.additionalUserInfo.profile.email,
                  // })
                  navigate('/newUser')
                } else {
                  navigate('/')
                }
              })
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
    }
  }

  return (
    <div style={{ backgroundColor: '#fff', height: '100vh' }}>
      <div style={{ fontSize: 20 }}>Please wait...{linkedInAuth()}</div>
    </div>
  )
}

export default Popup
